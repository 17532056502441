@import './textures.less';

div#videopanel {
	div#videocontainer {
		width: 90%;

		
		div#video_controls_top {
			display: flex;
    		background: linear-gradient(#004c10 -100%,#00923a);
    		border-radius: 3vw 3vw 0 0;
			height: 15vw;
			margin-bottom: 1vw;
	
			button#pass_video {
				right: 6vw;
				position: absolute;
		
				&::before {
					.sprite-textures();
					.textures-icon-cross();
					content: '';
					transform: scale(calc(0.6*var(--scale)));
					position: relative;
					transform-origin: top right;
					top: 1vw;
				}
		
				transition-duration: 100ms;
		
				&:active {
					transform: rotate(15deg) scale(0.7);
				}
			}
		}

		video#fullscreenvideo {
			width: 100%;
		}
		
		div#video_timeline_bg {
			width: 100%;
			height: 2px;
			background: linear-gradient(#454545c0 -100%,#000000c0);
		}
		
		div#video_timeline {
			width: 100%;
			height: 3px;
			background: #fffd8e;
			margin-top: -1px;
			top: -2px;
			position: relative;
		}
		
		div#video_controls {
			display: flex;
    		background: linear-gradient(#00923a -100%,#004c10);
    		border-radius: 0 0 3vw 3vw;
			height: 15vw;
			//border: 3px solid #004a0ceb;
	
			div#reward_container {
				display: flex;
				flex-direction: column;

				div#reward_tip {
					margin-top: 0.2em;
					margin-left: 1em;
					color: #ffffff;
					font-weight: 600;
					font-size: 3vw;
					font-weight: bolder;
				}

				div#reward_wrapper {
					display: flex;
					flex-direction: row;
					align-items: center;
					height: 10vw;

					div#reward_icon {
						.sprite-textures();
						.textures-icon-gem();
						content: '';
						transform-origin: center;
						transform: scale(calc(0.6*var(--scale)));
						margin-left: -4vw;
					}

					div#reward_value {
						transform-origin: left;
						color: #ffffff;
						font-weight: 600;
						font-size: 7vw;
						font-weight: bolder;
						margin-left: -5vw;
					}
				}
			}
		}
	}

	&.hidden {
		display: none;
	}

	background: linear-gradient(0deg, #000000c0, #454545c0 200%);
	position: absolute;
	z-index: 10;
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	height: 100%;
	pointer-events: auto;

	& > * {
		margin: 0vw auto 3vw;
	}
}
